/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useSiteMetadata } from "../hooks/useSiteMetadata"

import Footer from "./footer"
import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => {
  const data = useSiteMetadata()

  return (
    <div className="flex-auto bg-white flex flex-row">
      <div className="flex-auto"></div>
      <div>
        <Header siteTitle={data.title} />
        <main className="mx-8 my-8 max-w-screen-2xl">{children}</main>
        <Footer />
      </div>
      <div className="flex-auto"></div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
