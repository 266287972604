import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from "./header.module.css"
import selfie2 from "../images/selfie2.png"

const Menu = () => (
  <div className="flex flex-row text-2xl">
    <Link to="/" className="no-underline">
      <span>Home</span>
    </Link>
    <Link to="/writing" className="no-underline ml-14">
      <span>Writing</span>
    </Link>
    <Link to="/about" className="no-underline ml-14">
      <span>About</span>
    </Link>
  </div>
)

export default Menu
