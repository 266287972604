import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import React from "react"

const Footer = () => (
  <footer className="flex flex-row py-8 px-10 border-t">
    <div className="flex-auto text-xl text-gray-400 font-bold">
      &#169; 2019 Allan Bond
    </div>
    <div>
      <a
        href="https://www.linkedin.com/in/allan-bond"
        target="_blank"
        rel="noopener noreferrer"
      >
        <FontAwesomeIcon
          icon={faLinkedin}
          size="2x"
          className="text-gray-400"
        />
      </a>
    </div>
  </footer>
)

export default Footer
