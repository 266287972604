import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import headerStyles from "./header.module.css"
import selfie2 from "../images/selfie2.png"
import { useSiteMetadata } from "../hooks/useSiteMetadata"
import Menu from "./menu"

const Header = ({ siteTitle }) => {
  const data = useSiteMetadata()
  return (
    <header className="font-heading flex flex-col h-12 mt-10 mb-10">
      <div className="flex flex-row text-lg text-black">
        <span className="flex-auto"></span>
        <div className="flex flex-row">
          <Menu></Menu>
        </div>
      </div>
    </header>
  )
}
Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
